import axios from 'axios';

let instance = null;
class GDSAxios {
  constructor() {
    if (instance) return instance;

    // eslint-disable-next-line no-undef
    const url = __VUE_WORDPRESS__.routing.url.replace(':8888', ':3000');
    this.ajax = axios.create(
      {
        baseURL: `${url}/wp-json/`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    this.ajax.interceptors.request.use((config) => {
      if (window.location.href.includes('/preview/')) {
        // eslint-disable-next-line no-undef
        config.headers['X-WP-Nonce'] = __VUE_WORDPRESS__.state.session.nonce;
      }
      return config;
    });

    instance = this;
    return instance;
  }
}

export default GDSAxios;
