import Vue from 'vue';
import Wrapper from '@/components/layout/wrapper';
import Col from '@/components/layout/col';
import Flex from '@/components/layout/flex';
import Grid from '@/components/layout/grid';
import Spacer from '@/components/layout/spacer';

Vue.component('Wrapper', Wrapper);
Vue.component('Column', Col);
Vue.component('Flex', Flex);
Vue.component('Grid', Grid);
Vue.component('Spacer', Spacer);
