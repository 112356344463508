<template>
  <component
    :is="tag"
    :class="[
      template,
      ...gridCol,
      ...gridJustify,
      ...gridAlign,
      ...extraClass,
      rowGap && rowGap !== '' ? `row-gap-${rowGap}` : false,
      columnGap && columnGap !== '' ? `column-gap-${columnGap}` : false,
      !grid ? 'grid--default' : false,
    ]"
    :style="grid ? { '--columns' : grid } : undefined"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'Grid',
  props: {
    grid: {
      type: String,
      default: () => {},
    },
    tag: {
      type: String,
      default: 'div',
    },
    col: {
      type: [Object, String],
      default: '',
    },
    rowGap: {
      type: String,
      default: '',
    },
    columnGap: {
      type: String,
      default: '',
    },
    template: {
      type: [Object, String, Array],
      default: 'grid',
    },
    extraClass: {
      type: [Array],
      default: () => [],
    },
    justify: {
      type: [Object, String],
      default: '',
    },
    align: {
      type: [Object, String],
      default: '',
    },
  },
  computed: {
    gridCol() {
      const { col } = this;
      const cols = [];
      if (typeof col === 'string' && col !== '') {
        cols.push(`grid-${col}`);
      } else if (typeof col === 'object') {
        Object.keys(col).forEach((key) => {
          if (key !== 'default') {
            cols.push(`grid-${key}-${col[key]}`);
          } else {
            cols.push(`grid-${col[key]}`);
          }
        });
      }

      return cols;
    },

    gridJustify() {
      const { justify } = this;
      const justifys = [];
      if (typeof justify === 'string' && justify !== '') {
        justifys.push(`justify-${justify}`);
      } else if (typeof justify === 'object') {
        Object.keys(justify).forEach((key) => {
          if (key !== 'default') {
            justifys.push(`justify-${key}-${justify[key]}`);
          } else {
            justifys.push(`justify-${justify[key]}`);
          }
        });
      }

      return justifys;
    },

    gridAlign() {
      const { align } = this;
      const aligns = [];
      if (typeof align === 'string' && align !== '') {
        aligns.push(`align-${align}`);
      } else if (typeof align === 'object') {
        Object.keys(align).forEach((key) => {
          if (key !== 'default') {
            aligns.push(`align-${key}-${align[key]}`);
          } else {
            aligns.push(`align-${align[key]}`);
          }
        });
      }

      return aligns;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid--default {
  --columns: #{map-get($grid, columns)};
}
</style>
